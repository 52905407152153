import React, { useEffect, useState } from 'react'
import { initial, size } from 'lodash';
import "./Followers.scss"
import { useQuery } from '@apollo/client';
import { GET_FOLLOWERS , GET_FOLLOWEDS } from '../../../../gql/follow';
import { COUNT_LIKE_USER } from '../../../../gql/like';
import ListUsers from '../../ListUsers';
import { Icon } from 'semantic-ui-react';
import ModalBasic from "../../../ModalBasic/ModalBasic";




function Followers(props) {
    const {username,totalPublications,getUser}  = props;
    const [showModal,setShowModal] = useState(false );
    const [titleModal, setTitleModal] =useState ("") ;
    const [childrenModal, setChildrenModal] = useState (null)



    const { data:data1, loading: loading1 ,refetch: refetch1} = useQuery (COUNT_LIKE_USER,{
      variables: { idUser: getUser.id }
  });

  




//console.log(getUser);
//console.log(data1)

    const {data:dataFollowers ,
       loading:loadingFollowers,
       startPolling: startPollingFollowers,
       stopPolling: stopPollingFollowers,
       } = useQuery (GET_FOLLOWERS,{
    variables : {username},
    });

    const {data:dataFolloweds,
           loading:loadingFolloweds,
          startPolling:startPollingFolloweds,
          stopPolling:stopPollingFolloweds}= useQuery(
            GET_FOLLOWEDS, {variables: {username},});


    useEffect(()=> {
      startPollingFollowers(2000);
      return () => {
        stopPollingFollowers ();
      }
    },[startPollingFollowers, stopPollingFollowers]);

    const openFollowers = () =>{
      setTitleModal("seguidores");
      setChildrenModal(<ListUsers users= {getFollowers} setShowModal={setShowModal}/>)
      setShowModal(true);
    }

/*
    const openLikes = () =>{
      setTitleModal("Likes totales");
      setChildrenModal(<Icon name = "heart"  setShowModal={setShowModal} /> )
      setShowModal(true);

    }

*/

    if (loadingFollowers || loadingFolloweds) return null;
    const {getFollowers} = dataFollowers;
    const {getFolloweds} = dataFolloweds;
    if (loading1) return null ;
    const {countLikeUser} = data1;


  return (
    <>
    <grid mobile = {16}>
    <div className='follow'  mobile = {16} >
        <p><span>{totalPublications}</span> Publicaciones </p>
        <p className='link' onClick={openFollowers}><span>{size(getFollowers)}</span> Seguidores </p>
        
        <p className='link'>   
          <span>{countLikeUser}  </span>  {countLikeUser=== 1 ? "Like" : "Likes"}
        </p>
        
    </div>

    </grid>
  <ModalBasic show={showModal} setShow={setShowModal}   title={titleModal}> 
    {childrenModal}  
  
  </ModalBasic>

</>



  )
}

export default Followers