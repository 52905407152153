import React from 'react'
import { GridColumn, Icon, Grid, Image } from 'semantic-ui-react'
import avat from "../../../asets/png/avat.png"

import zangano from "../../../asets/png/zangano.png"
import elzangano from "../../../asets/png/elzangano.jpeg"
import reina from "../../../asets/png/reina.jpg" 
import aveja from "../../../asets/png/aveja.jpg"
import gans from "../../../asets/png/gans.png"
import mercado from "../../../asets/png/mercado.png"
import niña from "../../../asets/png/niña.png"
import cielo from "../../../asets/png/cielo.png"
import flor from "../../../asets/png/flor.png"
import ciudad from "../../../asets/png/ciudad.png"
import favela from "../../../asets/png/favela.jpg"
import atizapan from "../../../asets/png/atizapan.jpg"
import lguadalupe from "../../../asets/png/lguadalupe.jpg"
import bramader from "../../../asets/png/bramader.jpg"
import pedro from "../../../asets/png/pedro.jpg"
import cielo4 from "../../../asets/png/cielo4.png"
import cielo2 from "../../../asets/png/cielo 2.png"


  
import Footer from '../Footer/Footer'

import "./Info.scss"


function Info() {




  return (

    <Grid className='info' centered mobile= {16} >

      <GridColumn computer={3} mobile= {16} className="ima">
        <Image src= {cielo} fluid />
        <h2> Compra, vende, anúnciate y contacta por WhatsApp </h2>
        


      </GridColumn>



      <GridColumn computer={8} centered mobile= {16}>
        <Image src= {lguadalupe}/>
        
        <h2> De persona a persona de tu colonia o localidad.</h2>
        

      </GridColumn>




      
      <GridColumn computer={5} mobile= {16}>
        <Image src= {pedro}/>
        <h2> Toma precauciones si te reúnes con alguien, considera lugares públicos o pequeños establecimientos.  </h2>
        






      </GridColumn>

      <GridColumn centered computer={16} mobile= {16}>

        <Footer/>
      </GridColumn>













    </Grid>




    
  )
}

export default Info