import React from 'react'
import { Grid ,  GridColumn} from 'semantic-ui-react';
import { map } from 'lodash';
import "./Publication.scss"
import Preview from './PreviewPublication./Preview';
import Public from './public/Public';
import { GET_PUBLICATIONS } from '../../gql/publication';

function Publications(props){
    const {getPublications } = props;












  return (
    <div className='publications' mobile = {16} computer={8}>
       <h1>
         
         Publicaciones

       </h1>

       <Grid columns={2} mobile = {16} >
          
          {map(getPublications, (publication, index)=> (
            <Grid.Column key= { index }>
                <Preview publication= {publication}  />
              
                </Grid.Column>

                ))}

            


         
         </Grid>



         <div>

          
         </div>
     


    </div>







  )
}

export default Publications