import React from 'react'
import {Form,Button} from "semantic-ui-react";
import {useFormik} from  "formik";
import * as Yup from "yup" ;
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../../gql/user';
import "./PasswordForm.scss"
import { toast } from 'react-toastify';



export default function PasswordForm(props) {
    const {logout } = props;
    const [updateUser]= useMutation (UPDATE_USER);

    const formik = useFormik ({
        initialValues: initialValues(),
        validationSchema: Yup.object ({
            currentPassword: Yup.string().required(),
            newPassword: Yup.string ().required()
            .oneOf([Yup.ref("repeatNewPassword")]),

            repeatNewPassword: Yup.string().required()
            .oneOf([Yup.ref("newPassword")])
        }),

        onSubmit: async (formValues) => {
            try {
                const result = await updateUser({
                    variables:{
                        input:{
                            currentPassword:formValues.currentPassword,
                            newPassword: formValues.newPassword
                        },

                    },
                });
                if (!result.data.updateUser) {
                    toast.error("Error al cambiar la Contraseña");
                } else {
                    
                    logout();
                }
        
            } catch (error) {
                toast.error("Error al cambiar la Contraseña 7");
                
            }
        }

    })
  return (
    <div>
        <Form className='password-f' onSubmit={formik.handleSubmit}>
         <Form.Input type= "text" placeholder = "contraseña actual " name = "currentPassword"
         value= {formik.values.currentPassword} 
         onChange={formik.handleChange}
         error={formik.errors.currentPassword && true}  />
         <Form.Input placeholder = "Nueva contraseña"  name  =  "newPassword"
         value= {formik.values.newPassword} 
         onChange = {formik.handleChange} 
         error = {formik.errors.newPassword && true}
            />
         <Form.Input placeholder =  "Repite la Contraseña" name = "repeatNewPassword" 
         value= {formik.values.repeatNewPassword}
         onChange= {formik.handleChange}
         error= {formik.errors.repeatNewPassword && true}
         />

         <Button type = "submit" className='btn-submit'> Actualizar </Button>
         





        </Form>
    </div>
  )
}

function initialValues (){
    return{
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: ""
    }
}