import { gql } from "@apollo/client/";

export const REGISTER = gql`

mutation register ($input: UserInput) {
  register (input:$input) {
    id
    name 
    username 
    email 
    siteWeb
    description
    password
    avatar
    createAt
    area
    adminis
  }
}


`;

export const LOGIN1 =  gql`
mutation login ($input: LoginInput) {
  login (input: $input) {
    token
  }
}

`;

export const GET_USER = gql`

query getUser ($id:ID, $username:String ){
  getUser (id : $ id, username:$username){
    id
    name 
    username 
    email
    whatsapp
    siteWeb
    description
    avatar
    createAt
    area



  }

}
`;

export const  UPDATE_AVATAR = gql`
mutation updateAvatar($file: Upload){
  updateAvatar (file:$file) {
    status
    urlAvatar
    
  }
}


`;

export const DELETE_AVATAR = gql`
mutation deleteAvatar{
  deleteAvatar
}

`;

export const UPDATE_USER = gql`
 mutation updateUser ($input: UserUpdateInput){
  updateUser(input:$input)
}

`;

export const SEARCH =  gql`
query search ($search: String ) {
  search(search: $search) {
    id
    name 
    username 
    email 
    whatsapp
    siteWeb
    description
  
    avatar
    createAt
    area
    
  }
}

`;

export const UTER = gql`
query serachU($serachU: String) {
  search(search:$serachU)
  {
  name
  username
  email
    
  }
}
`;


export const DELETE_USER = gql`

mutation deleteUser($input:UserDeleteInput ){
  deleteUser(input:$input)
  
  
  
}


`;