import React from 'react'
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_NOT_FOLLOWEDS } from '../../../gql/follow';
import icona from "../../../asets/png/icona.jpg"
import "./UserNot.scss";

export default function UserNot() {

  const {data, loading  }= useQuery (GET_NOT_FOLLOWEDS)

  if (loading ) return null;

  const {getNotFolloweds} = data;

  



  return (
    <div className='users_not'>
    
    {map(getNotFolloweds,(user,index) => ( 
      <Link key={index} to= {`/${user.username}`} className= "users_not_user">
        <Image src= {user.avatar || icona } avatar />
        <span> {user.username}</span>
        

      </Link>
    )) }

    </div>
  )
}
