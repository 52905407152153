import React from 'react'
import { Form, Button, Icon, GridRow } from 'semantic-ui-react';
//import { useFormik } from 'formik';
//import * as Yup from "yup" ;
//import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client'
//import { UPDATE_USER } from '../../../gql/user';
import { Link , useNavigate, useHistory} from 'react-router-dom';
import "./GenerarReport.scss";


export default function GenerarReport(props) {
    const  {setShowModal,currentwhatsapp, refetch} = props

    const  history = useHistory ();
function onSubmit (){
history.push("/Rep");
}

  
  
  
    return (
      <> 
      <GridRow className='generar' >
      

<Icon name="address book outline" > </Icon> 




  


      </GridRow>
      <h1 className='bootom'>
      <Button onClick={()=> setShowModal (false)}> NO </Button>
          

          <Button onClick={onSubmit} type= "submit"  > SI</Button> 
          
          </h1>
      
          
         
          </>
  )

    }