import React from 'react'
import { useQuery } from '@apollo/client';
import { GET_PUBLICS } from '../../../gql/publication';
import { Icon } from 'semantic-ui-react';
import "./WhatSend.scss"




   
   

function WhatSend(props) {
const {publication} = props;
   
   
   // console.log (publication)
   
   
   const {data, loading}= useQuery(GET_PUBLICS,{
     variables: {
       idPublication: publication,
     }
   
   });
   
   if (loading) return null;
   
   const {getPublics} = data;
   
   console.log (data);

   console.log (getPublics[0].whats)
   





  return (
    <>
<div className=' what'>
    

<a href = {`https://api.whatsapp.com/send?phone= ${getPublics[0].whats}&text=Hola%20me%20interesá%20el%20articulo%20  ${getPublics[0].title}     publicado en %20%20la%20Colme%20`}   

target= " _ blank"
  rel="noopener noreferrer"
       >
 <Icon name='whatsapp'/>
</a>
<div>   <span> Toca el icono de Whatsapp para enviar </span>                                                                                                           </div>

</div>

</>
    
    
  )
}









export default WhatSend