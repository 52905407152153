
import React, {useState} from 'react';
import {Form, Button, Grid ,} from 'semantic-ui-react';
import { useFormik, } from 'formik';
import * as Yup from "yup" ;

import { useMutation } from "@apollo/client/react";
import {LOGIN1  } from '../../gql/user';
import {setToken , decodeToken , removeToken} from "../../../src/utils/token"
import useAuth from '../../hooks/useAuth';
import "../LoginForm/LoginForm.scss";



export default function LoginForm() {
  const [error, setError] = useState("");
  const [login ] = useMutation(LOGIN1);
  const  {setUser} = useAuth ();
  


  const formik = useFormik ({
    initialValues:initalvalue(),
     validationSchema: Yup.object ({
      email: Yup.string ()
      .email("El email no es valido ")
      .required("el email es obligatorio "),
      password: Yup.string ().required("la contraceña es requerida " )
     }),
     onSubmit: async (formdata) => {
      setError("");
    try {
      const { data } = await login ({
        variables: {
          input : formdata
        }
      });
      const{token} = data.login;
      setToken (token ) ;
      setUser (decodeToken(token));
      window.location.reload();
    }catch (error){

      

      setError(error.message);

      removeToken();
    
     }
    }
  });
  return (



<Form className='login-form' onSubmit={formik.handleSubmit}   >
  <h2>
    HOLA DE NUEVO 
  </h2>
  <Form.Input type= "text" placeholder = "Ingresa correo electronico"
   name= "email"
   onChange= {formik.handleChange}
   value={formik.values.email}  />
  <Form.Input type= "password" placeholder = " contraseña " name= "password"
  onChange= {formik.handleChange} 
  value={formik.values.password}/>
  
<Button type='submit'  className='btn-submit'>
    iniciar secion 
</Button>
 {error && <p className='submit-error'> {error}</p>}

</Form>



  );
}

function initalvalue () {
  return {
    email: "",
    password: "",
  };
}