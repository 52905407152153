// Layouts
import LayExterior from "../layouts/LayExterior";
//import LayFooter  from "../layouts/LayFooter"


// Pages
import inicio from "../../Homeventas/Inicio/Inicio";
//import User from "../pages/User";
import Error404 from "../../Error404/Error404";
//import Bienvenida from "../pages/Bienvenida/Bienvenida";
//import Report from  "../pages/Report/Report";
//import SearchPublic from "../pages/SearchPublic/SearchPublic";
//import SerachUser from "../pages/SerachUser/SerachUser";
//import Registre from "../pages/Regis/Registre";
import Portada from "../../Auth/Portada";
import Inicio from "../../Homeventas/Inicio/Inicio";
import Info from "../Info/Info";
import TM from "../TM/TM"
//import footer from "../Footer/Footer";
import Bienvenida from "../../Bienvenida/Bienvenida";



const links = [
  {
    path: "/",
    layout:LayExterior,
    component:inicio,
    exact: true,
    
  },
  {
    path: "/Bienvenida",
    layout: LayExterior,
    component: Portada,
    exact: true,
  },

  {
    path: "/info",
    layout: LayExterior,
    component: Info,
    exact: true,
  },
  {
    path: "/Tm",
    layout: LayExterior,
    component: TM,
    exact: true,
  },

  {
    layout: LayExterior,
    component: Error404,
  },

 


];

export default links;