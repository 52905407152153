import React, {useState} from 'react'
import {Image , Icon, Grid } from 'semantic-ui-react';
import {map} from "lodash"
import {GET_PUBLICS} from "../../../gql/publication";
import ModalPubication from '../../ModalPublicasion /ModalPubication';
import Title from '../../ModalUpload/Title/Title';
import { useQuery } from '@apollo/client';
import userAuth from '../../../hooks/useAuth';
import usePublic from '../../../hooks/usePublic';
import ModalBasic from "../../ModalBasic/ModalBasic";
import ComfirmDeletePublic from '../../ModalPublicasion /DeletePublication/ConfirmDelete/ComfirmDeletePublic';
//import Public from '../public/Public';
import "./Preview.scss" 



function Preview(props) {
    const {publication ,tublic} = props;
    const[showModal,setShowModal] = useState(false);
    const [show, setShow]= useState (false);
    const [titleModal , setTitleModal] = useState("");
    const [childrenModal, setChildrenModal]=  useState(null) ;
    const {auth} = userAuth ();
    const [publit, setPublit]= useState ([])
    const timeAgo = (publication.createAt);
    const date = ( new Date(timeAgo*1)).toLocaleTimeString()
    const date1 = ( new Date(timeAgo*1)).toLocaleDateString()
    


    const {data, loading}= useQuery(GET_PUBLICS,{
      variables: {
        idPublication: publication.id,
      }

    });
  
    if (loading) return null;
  
    const {getPublics} = data;




    const nuev = getPublics.map( (elem) => {
      return elem ;
  });
  

  console.log (nuev[0]);


  const pobli = (nuev[0])

  
 






 
    
    const handlerModal = (type) => {
      switch (type) {
        case "ajustes":
          setTitleModal("Agrega titulo y descripcion ");
          setChildrenModal( <Title publication={publication}  setShowModal={setShowModal}/>);
            
          
          
          break;
           case "publict" :
            
            setShow (true);

            break;
            case "delete":
              setTitleModal("Borrar publicacion");
              setChildrenModal ( <ComfirmDeletePublic publication={publication}  currentTitle={getPublics[0].title} setShowModal={setShowModal}/>);
             
            
              setShowModal (true);


        default:
          break;
          }
        }

       console.log (auth.id);
       console.log (auth);
       
       console.log (getPublics[0].idUser);

    


  return (




    <>

        


          <div  className=' preview_ti'>
          
        {map(getPublics, ( tublic, index)=> (
                    < div   key= { index } >
               
              
                <Grid.Column>
                 <p> {tublic.title}</p>
                 
                  </Grid.Column>
                  
                </div>
                 
                

          ))}

    </div>
        
        
        <div className='preview_foto' onClick={(onAction) => handlerModal("publict")}  >

          
            <Image className='preview_i' src={publication.file} />
            
            
            
            <div className='preview_description'>

              <span>   </span>

            {map(getPublics, ( ublic, index)=> (
                    < div   key= { index } >
                     
               
               <Grid.Column >
               <Icon name="dollar sign"    />
               <span> {ublic.precio} </span> 
              
         

                 <p>  {ublic.description}</p>

                

                  </Grid.Column>

                  


               

                </div>

               ))}

   

            </div>
            


              <div>

           

          </div>    

      </div>




      <div className='preview_fecha'>
      
          <Icon name="calendar alternate outline"    />
          <span>  {date1}</span>

  
          

          {auth.adminis || auth.id === getPublics[0].idUser ?  (

<Icon name = "trash alternate outline" onClick={() => handlerModal("delete")}/>

)
: (
  
  !loading && <Icon name = "check square outline"></Icon>
)}




</div>

















      <ModalPubication show={show}  setShow= {setShow} publication= {publication} getPublics={getPublics}   tublic = {nuev[0]}   />   

      <div>
      <ModalBasic show={showModal} setShow={setShowModal} title = {titleModal}>
     {childrenModal}
     </ModalBasic>

      </div>
      
    </>
  )
}


export default Preview