import React from "react";
import { Container } from "semantic-ui-react";
//import Header from "../components/Header /Header";
import HeaderEx from "../HeaderEx/HeaderEx"

export default function LayoutBasic1(props) {
  const { children } = props;

  return (
    <>
      <HeaderEx/>
      <Container className="layout-basic">{children}</Container>
    </>
  );
}