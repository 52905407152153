
import React, {useState, useEffect, useMemo, } from 'react';
import { ApolloProvider } from '@apollo/client';

import{ToastContainer} from "react-toastify";
import client from "./config/apollo";
import Auth from "./pages/Auth/exterior/Auth";
import { getToken, decodeToken,removeToken  } from './utils/token';
import AuthContext from '../src/context/AuthContext';
import Portada from './pages/Auth/Portada';
import Navigation from './routes/Navigation';
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';
import Inicio from './pages/Homeventas/Inicio/Inicio';
//import LayoutBasic1 from './layouts/LayoutBasic1';
import Header from './pages/Homeventas/HeaderEx/HeaderEx';
import Naviga from "./pages/Homeventas/Routerx/Naviga"
//import Bienvenida from './pages/Bienvenida/Bienvenida';



export default function App() {

  const [auth ,setAuth ] = useState ( false  );
  

  useEffect(() => {
    
    const token = getToken ();
    
    if ( !token ){
     setAuth  (null);

   } else {
     setAuth(decodeToken(token))  ;
    //window.location.reload();
      //<Link to = "/"></Link>
    }
  
  }, []) ;
  const logout = () => {
   
   removeToken();
  setAuth(null);
  };

  const setUser = () => {
  setAuth();
  };

 const authData = useMemo(
    () => ({
    auth,
     logout,
     setUser,
    }),
   [auth]
  );

 if ( auth === undefined) return null;
  
  return (
    
   

<ApolloProvider client={client}>
      <AuthContext.Provider value = {authData}>
      { !auth ? <Naviga/>:<Navigation/>  }


      <ToastContainer
      position='top-right'
      autoClose= {8000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl= {false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
   /</AuthContext.Provider>
   </ApolloProvider>

   

   
   
    );
}
