import React from 'react'
import {Grid ,Container, Icon , GridRow, GridColumn , Menu, MenuItem} from "semantic-ui-react";
import { Link } from 'react-router-dom';
//import useAuth from '../../hooks/useAuth';

//import FeedVent from './FedExterior/FedExterior';
import Footer from '../Footer/Footer';
import FedExterior from './../FedExterior/FedExterior';


import "./Inicio.scss";



export default function Inicio() {


  const avis = () => {
    
     alert("no has inicido sesion ")

};


const reload = () => {
    

  window.location.reload()
};

  

  return (

 
<>





    <GridColumn className='homex' centered mobile ={16} computer= {1} >

    






      
       <div>

        <FedExterior/>
        </div>

       









      </GridColumn>

     
 <GridColumn centered mobile= {16} className='onos' >


<Icon name= "user secret"  onClick={avis} />

  

<a href="whatsapp://send?text=Hola mira esta paguina local %20 de anuncios %20 https://lacolme.netlify.app/">
<Icon name= "share square"></Icon>
</a>




<Link to = "/info">
<Icon name= "question circle outline"/>
</Link>




<Link to = "/tm">
<Icon name= "trademark" />
</Link>





<Icon name= "redo"  onClick={reload} />






</GridColumn>













 </>


  )


}
