import React from 'react';
import { useQuery } from '@apollo/client';
import { map } from 'lodash';
import {GET_PUBLICS} from "../../../../gql/publication";
import { Icon } from 'semantic-ui-react';

import "./TitleEx.scss";




function Public(props) {
 const {publication} = props;


 //console.log (publication)


const {data, loading}= useQuery(GET_PUBLICS,{
  variables: {
    idPublication: publication._id,
  }

});

if (loading) return null;

const {getPublics} = data;

//console.log (data);





  


  return (

   
    
    <div>

     
        
        {map(getPublics, ( tublic, index)=> (
                    < div   key= { index } >

              
               
          
                <div className='tiex'>

                
                 <h1>{tublic.title}</h1>
                 <Icon name = "whatsapp"/>
                  </div>
                  

                 
                </div>

                


                



          ))}
           
    </div>


   
  )
}



 

export default Public