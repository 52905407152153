import React , {useEffect, useState}from 'react';
import { Image , Icon, GridRow} from 'semantic-ui-react';
import {map } from "lodash"
import {Link, Switch} from "react-router-dom"
import { useQuery } from '@apollo/client';
import { GET_SAM } from '../../../gql/publication';
import ModalBasic from '../../../components/ModalBasic';
import Price from '../../../components/Publications/public/pricefed';
import TitleEx from "../FedExterior/TitleEx/TitleEx"
//import Footer from "../Footer/Footer"


import WhatSend from '../WhatSend';




import "./FedExterior.scss";

export default function FedExterior() {

  //const {publication} = props;
  const [showModal, setShowModal] = useState (false);
  const [publicationSelect, setPublicationSelect ] = useState (null);
  const {data, loading,startPolling,stopPolling }= useQuery (GET_SAM);
  const [titleModal, setTitleModal] = useState ("")
  const [childrenModal, setChildrenModal] = useState (null) 
  //const {data1 , loading0}= useQuery (GET_PUBLICS)
  //const {pub , setPub} = useState (null);



  

  

useEffect(() => {
  startPolling(50000)
  return () => {
   stopPolling();
  }
}, [startPolling,stopPolling])





  if (loading) return null;
  const {getSample} = data;
  
  



  //console.log (data);
  //console.log (getSample ,"hola kakak");
  //console.log (publication.id)
  //console.log (publicationSelect, "hjhjhjhj");
 

  //const time = (getVentas [[1]]["createAt"]);
  
  //const date1 = ( new Date(time*1)).toLocaleDateString()





//console.log(data);

const openPublication = (publication) => {
    setPublicationSelect(publication);
    
    setTitleModal("Enviar whatsapp al vendedor");
      setChildrenModal(<WhatSend publication={ publication._id} /> )

    setShowModal(true);

  };


  return (

    <>

<div className='feedex' mobile = {16}  computer = {16}>





        {map (getSample,(publication, index) => (
          <div key = {index} className = "feedex_box">
            
              <div className='feed_box_user'>
              
                <TitleEx publication={publication}  />
               </div>

               <div className='feed_box_precio' mobile={16}>
   
               
            </div>
            <div  className='feedex_box_photo' style={{backgroundImage:`url("${publication.file}")`}}
            onClick={ ()=> openPublication (publication )} / >

   
  

            <div className='feedex_box_ubic'>

              <Icon name = "map marker alternate"/>
               <span>{publication.area}</span>
               
              
            </div>

           

             
            </div>

        ))}
    </div>


 



    {showModal && (
      <ModalBasic  show= {showModal}  setShow= {setShowModal}   title = {titleModal} children={childrenModal}  publication={publicationSelect}    /> )}









    
</>
  
  
 ) 
  
  };
