import React ,{ useState} from "react";
import { Grid, GridColumn , Image, GridRow , Icon  } from "semantic-ui-react"
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../../gql/user";
import userAuth from "../../../hooks/useAuth";
import UserNotFoud from "../../UserNotFoud";
import ModalBasic from "../../ModalBasic";
import AvatarForm from "../AvatarForm";
import HeaderProfile from "../HeaderProfile";
import SettingsForm from "../HeaderProfile/SettingsForm/SettingsForm";
import UtilForm from "../HeaderProfile/UtilForm/UtilForm";
import DeleteUser from "../HeaderProfile/UtilForm/Report/DeleteUser";
import icona from "../../../asets/png/icona.jpg";
import Folllowers from "./Folllowers";
import {Link} from "react-router-dom"
import "./Profile.scss";
import { get } from "lodash";




export default function Profile (props){
 const {username ,totalPublications } = props ;
 const [showModal, setShowModal] = useState(false);
 const [titleModal , setTitleModal] = useState("");
 const [childrenModal, setChildrenModal]=  useState(null) ;

 const {auth} = userAuth ();
 const [wha ,setWha] = useState (null);
 const { data, loading, error, refetch} = useQuery(GET_USER, {
variables: { username },
  

});

 

 if (loading) return null ;
 if (error) return <UserNotFoud/>;
 const { getUser } = data;

 
 

 
 const handlerModal = (type) => {
  switch (type) {
    case "avatar":
      setTitleModal("Cambiar foto perfil");
      setChildrenModal(
        <AvatarForm setShowModal={setShowModal} auth={auth} />);
      setShowModal(true);
      
      break;
       case "settigns" :
        setTitleModal ("Ajustes");
        setChildrenModal(<SettingsForm setShowModal= {setShowModal}
        setTitleModal = {setTitleModal}
        setChildrenModal ={setChildrenModal}
        getUser= {getUser}

        refetch = {refetch} 
        />);
        setShowModal(true);
        break; 
        case "Report":
          setTitleModal("Herramientas");
          setChildrenModal
            (<UtilForm setShowModal= {setShowModal}
              setTitleModal = {setTitleModal}
              setChildrenModal ={setChildrenModal}/>
             );
          setShowModal(true);
          break;

          case "delete":
            setTitleModal("Eliminar Usuario");
            setChildrenModal(<DeleteUser setShowModal= {setShowModal}
                setTitleModal = {setTitleModal}
                setChildrenModal ={setChildrenModal}
                getUser= {getUser}
                refetch ={refetch}/>
               );
            setShowModal(true);
            break;
  


    default:
      break;
  }

 }


 //const liw ='https://api.whatsapp.com/send?phone='+b+'&text=Hola%20me%20interesá%20el%20articulo%20publicado%20en%20la%20colme%20' ;

 //console.log = setWha ;


 return(

    <> 
    
<GridColumn computer={5}  mobile= {16} height= {1}className="profile_name" > <h1> {getUser.area} </h1></GridColumn>
    <Grid className="profile">

      <GridColumn computer= {5}  mobile = {16}className= "profile-left">
        <Image src = {getUser.avatar? getUser.avatar:icona} avatar 
        onClick = {()=> username === auth.username && handlerModal("avatar")} />
       
      </GridColumn>
    
      <Grid.Column computer ={11}  mobile={16}className = "profile_righ"> <HeaderProfile getUser = {getUser} auth = {auth}
      handlerModal = {handlerModal}/>
        <Folllowers username={username} getUser = {getUser} totalPublications= {totalPublications}/>
        <div className="other">
          
         {getUser.whatsapp
           && (
          <span>
          <Icon name = "whatsapp"/>
            
          <span>{getUser.whatsapp}</span>
          

          </span>
          )}
          {getUser.description && (
            <p className="description ">{getUser.description}</p>

          )}
      
        </div>
        
      </Grid.Column>
     
      <GridColumn computer = {5}  mobile= {5} className="profile_name" > <h1> {getUser.name} </h1></GridColumn>
     </Grid>
     
     
     <ModalBasic show={showModal} setShow={setShowModal} title = {titleModal}>
     {childrenModal}
      

     </ModalBasic>



      
    
      </>


 );



}

/*<a href = {`https://api.whatsapp.com/send?phone=${getUser.whatsapp}&text=Hola%20me%20interesá%20el%20articulo%20publicado%20en%20la%20Colme%20`}  className = "whatsapp" 

          target= " _ blank"
            rel="noopener noreferrer"
             >
            {getUser.whatsapp}
          </a>  */