import React, { useState , useEffect } from 'react'
import { Image, Button, Search as Sear, ButtonContent , GridRow,GridColumn , Grid ,TableRow, TableHeaderCell, TableHeader,TableCell, TableBody, Header,Table,Rating,TableFooter } from 'semantic-ui-react';
import { map , size } from 'lodash';
import { useQuery } from '@apollo/client';
import { SEARCH  } from '../../../../gql/user';
import { GET_PUBLICATIONS , GET_PUBLICS} from '../../../../gql/publication';
import { Link } from 'react-router-dom';
import icona from "../../../../asets/png/icona.jpg"

import "./Users.scss"


export default function Users() {

  const [searchi, setSearchi] = useState();
  
 // const date1 = ( new Date(timeAgo*1)).toLocaleDateString()

  const {data, loading  }= useQuery ( SEARCH, {
    variables:{search: "" },

  });

  if (loading ) return null;

/*
const onSubmit = (e) => {
    if (e.target.value)setSearch (e.target.value)

    
;*/

  
    const users =[];
    data.search.forEach((user,index) =>{
      users.push({
        key:index,
        title:user.name,
        avatar:user.avatar,
        username:user.username,
        whatsapp:user.whatsapp,
        email:user.email,
        createAt:user.createAt,
        description:user.description,
        

        

       
      })
    

    });

    //setSearchi ([users])

    
  
  
  
console .log (users)
  

  //

  console.log (data);
 

     




  return (



    

<Table celled fixed  padded>

<TableHeader>
      <TableRow>
        <TableHeaderCell >Imagen</TableHeaderCell>
        <TableHeaderCell>Nombre</TableHeaderCell>
        <TableHeaderCell>Username</TableHeaderCell>
        <TableHeaderCell>Correo electronico</TableHeaderCell>
        <TableHeaderCell>Whatsapp</TableHeaderCell>
        <TableHeaderCell>description</TableHeaderCell>
        <TableHeaderCell>Alta</TableHeaderCell>


      </TableRow>
    </TableHeader>

 

<TableBody>

  

 
      
{map(users,(user,index) => (



<TableRow key={index} to= {`/${user.username}`} className= "userl">

     


        
     <Link key={index} to= {`/${user.username}`}> < TableCell ><Image src= {user.avatar || icona } avatar /></TableCell> </Link>
        <TableCell><span> {user.title}</span></TableCell>
        <TableCell><span> {user.username}</span></TableCell>
        <TableCell><span>{user.email}</span></TableCell>
        <TableCell><span> {user.whatsapp}</span></TableCell>
        <TableCell><span> {user.description}</span></TableCell>
        <TableCell><span> { ( new Date(user.createAt*1)).toLocaleDateString()

 }</span></TableCell>




      </TableRow>

))
   





}


</TableBody> 

<TableFooter>
      <TableRow>
        <TableHeaderCell>People</TableHeaderCell>
        <TableHeaderCell>{size (users)}</TableHeaderCell>
        <TableHeaderCell />
      </TableRow>
    </TableFooter>


</Table>
  

)};







