import React from 'react';
import { useQuery } from '@apollo/client';
import { map } from 'lodash';
import {GET_PUBLICS} from "../../../../gql/publication";
import Preview from "./../../PreviewPublication./Preview";
import { Icon } from 'semantic-ui-react';
import Publications from '../../Publications';
import "./Price.scss";




function Price (props) {
 const {publication } = props;

const {data, loading }= useQuery(GET_PUBLICS,{
  variables: {
    idPublication: publication._id,
  }

});

if (loading) return null;

const {getPublics} = data;



//console.log (getPublics ,"holaasasasaaa");
  


  return (
    
    <div  className='price'>
        
        {map(getPublics, ( tublic, index)=> (
                    < div   key= { index } >
                
          
                <div className='price_1'>
                
                 <h2><Icon name = "dollar"/>  {tublic.precio}</h2>
                  </div>
                  
                </div>





          ))}
           
    </div>




  )
}



 

export default Price