import React from 'react';
import { Image } from 'semantic-ui-react';
import {size , map} from "lodash";
import { useHistory } from 'react-router-dom';
import icona from "../../../asets/png/icona.jpg"
import "./ListUsers.scss";


export default function ListUsers(props) {
  const {users, setShowModal} = props ;
  const history = useHistory ();

  const goToUser = (username) =>{
    setShowModal(false);
    history.push(`/${username}`);
  }







  return (
    
        <div className='list-u'>
          {size(users) === 0 ? (
            <p className='list-u_no'>No se encontraron usuarios </p>
          ) :(
            map(users,(user,index) => (
              <div key ={index} className = "list-u_user" onClick={() => goToUser (user.username )}>
                <Image src = {user.avatar || icona} avatar />
                <div>
                  <p>{user.name}</p>
                  <p>
                    {user.username}
                  </p>
                  
                  
                  </div>
              </div>
            ))
          )}

        </div>
    
  );
}

