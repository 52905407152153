import React ,{  useState } from "react";
import {Icon,Image} from "semantic-ui-react"
import {Link} from "react-router-dom";
import { useQuery } from "@apollo/client";

//import useAuth from "../../hooks/useAuth";
//import ModalUpload from "../ModalUpload";
//import icona from "../../asets/png/icona.jpg";
import {useHistory} from "react-router-dom"
import {  useApolloClient } from '@apollo/client';
import "./RighHeadEx.scss";
import { get } from "lodash";



export default function RighHeadEx() {
  
  /*const[showModal , setShowModal ] = useState (false);
  const history = useHistory ();
  const client = useApolloClient ();
  //const {logout} = useAuth ();


  /*const {auth} = useAuth();
  const {data,loading,error} = useQuery (GET_USER,{
    variables:{ username:auth.username},
  });

  if (loading ||error ) return null;
  const {getUser} = data ;
  
  const onLogout = () => {
    client.clearStore();
     logout();
    history.push ("/");
  }
  */
  return (

    <>
    <div className ="rhR"  >

       <Link  to = "/Bienvenida">
      
       <Icon name = "sign-in alternate" />
         
         <p>iniciar</p>
     

       </Link>

   </div>
   
                      
   

</>
  )
}
