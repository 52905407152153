import React , {useEffect, useState}from 'react';
import { Image } from 'semantic-ui-react';
import {map } from "lodash"
import {Link, Switch} from "react-router-dom"
import { useQuery } from '@apollo/client';
import { GET_VENTAS , GET_PUBLICS} from '../../../gql/publication';
import Actions from '../../../components/ModalPublicasion /Actions';
import ActionsVent from "../../../components/ModalPublicasion /ActionsVent"
import ModalPubication from '../../../components/ModalPublicasion /ModalPubication';
import icona from "../../../../src/asets/png/icona.jpg";
import Public from '../../../components/Publications/public';
import Price from '../../../components/Publications/public/pricefed';
import TitlePublic from '../../../components/ModalPublicasion /titlepublic/TitlePublic';
import "./FeedVent.scss";

export default function FeedVent(props) {

  const {publication} = props;
  const [showModal, setShowModal] = useState (false);
  const [publicationSelect, setPublicationSelect ] = useState (null);
  const {data, loading, startPolling, stopPolling }= useQuery (GET_VENTAS);
  //const {data1 , loading0}= useQuery (GET_PUBLICS)
  //const {pub , setPub} = useState (null);
  

  

useEffect(() => {
  startPolling(2000)
  return () => {
   stopPolling();
  }
}, [startPolling,stopPolling])

  if(loading) return null;
  const {getPublicationsVent} = data;
  
  //console.log (data);
  //console.log (getPublicationsVent ,"hola kakak");
  //console.log (publication.id)
  //console.log (publicationSelect, "hjhjhjhj");
 

  //const time = (getVentas [[1]]["createAt"]);
  
  //const date1 = ( new Date(time*1)).toLocaleDateString()





//console.log(data);













  


  
  const openPublication = (publication) => {
    setPublicationSelect(publication);

   






    setShowModal(true);

  };


  return (

    <>

<div className='feed' mobile = {16}  computer = {12}>
  
        {map (getPublicationsVent,(publication, index) => (
          
          <div key = {index} className = "feed_box" mobile={16}>
  

            <Link to = {`/${publication.idUser.username}`}>
              <div className='feed_box_user' mobile={16}>
                <Image src= {publication.idUser.avatar  || icona } avatar />
                <span>{publication.idUser.name}</span>
                <p>{( new Date(publication.createAt*1)).toLocaleDateString() }</p>  
                </div>
                <div className='feed_box_user' mobile={16}>
                
              <TitlePublic publication={publication}/>
      </div>    
    </Link>
    
            <Image className='feed_box_photo'mobile={16}
             src= {publication.file} 
            onClick={ ()=> openPublication (publication )}


            
            
            
            
            />


            <div className='feed_box_action'>

            
            
            
            </div>

            <div className='feed_box_user'   mobile={16}>

<  Price publication={publication}   />  



</div>

           

              
              
          </div>

        ))}
    </div>









    {showModal && (
      <ModalPubication  show= {showModal}  setShow= {setShowModal}    publication={publicationSelect}    /> )};



















    
</>
  
  
 ) 
  
  };
