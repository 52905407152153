import React from 'react';
import { Button, Container, Form } from 'semantic-ui-react';
import { GET_PUBLICS } from '../../../gql/publication';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

import "./TitlePublic.scss";

export default function TitlePublic(props) {
  const {publication }= props;
  //console.log(publication);

  const {data, loading}= useQuery(GET_PUBLICS,{
    variables: {
      idPublication:publication.id  || publication._id,
    }
  
  });
  
  if (loading) return null;
  
  const {getPublics} = data;
  
 // console.log (data)
  
  //console.log(publication.username)
  
  
    



  
  return (

    <Container className='title' >
    
    
    <h2> {getPublics[0].title}</h2>

    </Container>



  )
  }
