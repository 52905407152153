import React from 'react'
import {Form , Button } from "semantic-ui-react";
import { useFormik } from 'formik';
import * as Yup from "yup" ;
import { useMutation } from '@apollo/client';
import { DELETE_USER } from "../../../../../../../gql/user";

import { toast } from 'react-toastify';

import "./ComfirmDelete.scss" ;

 export default function ComfirmDelete (props) {
    const {setShowModal, currentUser , refetch } = props ;
    const[deleteUser] = useMutation(DELETE_USER);

    const formik = useFormik({
      initialValues : {
        username: "", 
      },
      validationSchema: Yup.object ({
        username: Yup.string().required()
        .oneOf([Yup.ref("repeatUsername")]),
        repeatUsername: Yup.string().required()
            .oneOf([Yup.ref("username")])
        
      }),
      onSubmit: async(FormData) => {
        console.log({username:FormData.username})
        try { await deleteUser ({
          variables: {
            input: {username:FormData.username}
            }
            
          });
          console.log (FormData)
          refetch();
          setShowModal(false);
        } catch (error) { 
          toast.error("Usuario no borrado  ")
          
        }

      }   

    })

   


  return (

    <Form className='confirm-form' onSubmit={formik.handleSubmit}>
        
        
        <Form.Input 
         placeholder = {currentUser}name= "username" value={formik.values.username} onChange={formik.handleChange}
        error={formik.errors.username && true}
        />
        <Form.Input 
         placeholder = {currentUser} name= "repeatUsername" value={formik.values.repeatUsername} onChange={formik.handleChange}
        error={formik.errors.repeatUsername && true}
        />


        <Button type = "submit" className='btn-remove' >Borrar</Button>

    </Form>
    
  );

}


