import React from 'react';
import { Button } from 'semantic-ui-react';
import {useHistory} from "react-router-dom"
import {  useApolloClient } from '@apollo/client';
import useAuth from '../../../../hooks/useAuth';
import PasswordForm from '../../PasswordForm';
import EmailForm from '../../EmailForm/EmailForm';
import DescriptionForm from '../../DescriptionForm/DescriptionForm';
import WhatsAppF from '../../WhatsAppForm/WhatsAppF';
import {Link} from "react-router-dom"
import "./SettingForm.scss";


export default function SettingsForm(props) {
    const {setShowModal , setTitleModal, setChildrenModal ,getUser ,refetch } = props;
    const history = useHistory ();
    const client = useApolloClient ();
    const {logout} = useAuth ();

    const onChangePassword = () => {
      setTitleModal("Cambiar tu Contraseña");
      setChildrenModal( < PasswordForm logout = {onLogout} />
        
       )
    };

    const onChangeEmail = () => {
      setTitleModal ("cambiar el email");
      setChildrenModal (< EmailForm  setShowModal={setShowModal} 
        currentEmail = {getUser.email}
        refetch= {refetch}
     />)

    }

    const onChangeDescription = () => {
      setTitleModal( "Cambia tu descripcion ");
      setChildrenModal(<DescriptionForm 
        setShowModal={setShowModal}
          currentDescription = {getUser.description}
            refetch={refetch}
      
      />) ;
    }
     
    const onChangeWhattsApp = () => {
      setTitleModal ("Cambia tu Whattsapp");
      setChildrenModal ( <WhatsAppF
        setShowModal={setShowModal}
        currentsiteWeb = {getUser.siteWeb}
          refetch={refetch}
    />)
    }





    const onLogout = () => {
      client.clearStore();
      logout();
      history.push ("/");
    }


  return (
    <div className='setting-form'>
        <Button onClick={ onChangePassword}> Cambiar Contraseña</Button>
        <Button onClick={onChangeEmail}> Cambiar Email</Button>
        <Button onClick={onChangeDescription}> Descripcion </Button>
        <Button onClick={onChangeWhattsApp}> WhatsApp</Button>
        <Button onClick={onLogout}> Cerrar sesion  </Button>
        <Button onClick={()=> setShowModal (false)}> Cancelar  </Button>
    </div>
  )
}
