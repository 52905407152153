import {gql} from "@apollo/client"



export const IS_SOLD = gql`


 query isSold($idPublication: ID!){
    isSold(idPublication:$idPublication)
}
 
  `;




export const ADD_SOLD = gql`

mutation addSold($idPublication:ID!){
  addSold(idPublication:$idPublication)
  }
  
  `;

export const COUNT_SOLD = gql`
query countSolds ($idPublication:ID!){
  countSolds(idPublication:$idPublication)

}
 
`;
