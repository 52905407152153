import { gql } from "@apollo/client";

export const ADD_COMMENT = gql`
 mutation addComment($input: CommentInput) {
    addComment(input: $input) {
      idPublication
      comment
     description
      createAt
    }
  }


`;


export const GET_COMENTS = gql`
query getComments($idPublication: ID!) {
    getComments(idPublication: $idPublication) {
      idPublication
    idUser{
      name
      username
      avatar
    }
    comment
    description
    createAt
    }
  }

`