import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useQuery ,useMutation } from '@apollo/client';
import { IS_FOLLOW , FOLLOW ,UN_FOLLOW} from '../../../gql/follow';

import "./HeaderProfile.scss";
import { date } from 'yup';



export default function HeaderProfile (props) {
    const {getUser , auth, handlerModal  } = props;
    const [follow] = useMutation (FOLLOW);
    const [unFollow]= useMutation(UN_FOLLOW);
    let hoy = Date.now()*1;
    //const hoy = hoyo.getDate();
    //const date0 = date.toLocaleTimeString()
    //let date1 = hoyo.toLocaleDateString()



    const {data ,loading , refetch } = useQuery(IS_FOLLOW, {
      variables: { username:getUser.username },

    });

    const [] = useMutation (FOLLOW)

   

   const ButtonFollow = () => {
    if (data.isFollow) {
      return(
       <Button className='btn_danger' onClick={uFollow}>
          Dejar de seguir
        </Button>
     )
   } else {
     return (
      <Button className='btn_action' onClick={onFollow}>
           Seguir
        </Button>
     )
    }
   }




   const onFollow = async () => {
    try {

      await follow ({
        variables: {
          username:getUser.username,
        },
      });
      refetch ()
      
    } catch (error) {
      //console.log(error);
    }

   }

   const uFollow = async () => {
    try {

      await unFollow  ({
        variables: {
          username:getUser.username,
        },
      });
      refetch ()
      
    } catch (error) {
      //console.log(error);
    }

   }




  return (
    <div className ="header-profile" mobile={16}>
        <h2> { getUser.username } </h2>
        {getUser.username === auth.username ? (
        <Icon name = "tasks" onClick={() => handlerModal("settigns")}/>
        ): (
        
        !loading && ButtonFollow ()

        )}

      {auth.adminis & getUser.username === auth.username ?  (

      <Icon name = "ellipsis horizontal" onClick={() => handlerModal("Report")}/>
      
      )
      : (
        
        !loading && <Icon name = "copyright"></Icon>
      )}

         {auth.adminis & getUser.username != auth.username ? (

<Icon name = "user delete" onClick={() => handlerModal("delete")}/>
         )

: (
        
  !loading && <span className='fecha'>{  new Date(hoy).toLocaleDateString()  } </span>
)}













 </div>
 );
}
