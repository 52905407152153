// Layouts
import LayoutBasic from "../layouts/LayoutBasic";
//import LayoutBasic1 from "../layouts/LayoutBasic1";

// Pages
import HomeVenta from "../pages/Homeventas";
import User from "../pages/User";
import Error404 from "../pages/Error404";
import Bienvenida from "../pages/Bienvenida/Bienvenida";
import Report from  "../pages/Report/Report";
import SearchPublic from "../pages/SearchPublic/SearchPublic";
import SerachUser from "../pages/SerachUser/SerachUser";
import Registre from "../pages/Regis/Registre";
//import Inicio  from "../pages/Homeventas/Inicio/Inicio";




const routes = [
  {
    path: "/Bienvenida",
    layout: LayoutBasic,
    component: Bienvenida,
    exact: true,
  },
  {
    path: "/",
    layout: LayoutBasic,
    component: Bienvenida,
    exact: true,
  },

  {
    path: "/HomeVenta",
    layout: LayoutBasic,
    component: HomeVenta,
    exact: true,
  },
  {
    path: "/SearchPublic",
    layout: LayoutBasic,
    component: SearchPublic,
    exact: true,
  },
  {
    path: "/Buscar",
    layout: LayoutBasic,
    component: SerachUser,
    exact: true,
  },
  {
    path: "/Registre",
    layout: LayoutBasic,
    component: Registre,
    exact: true,
  },



  {
    path: "/Rep",
    layout: LayoutBasic,
    component: Report,
    exact: true,
  },
  {
    path: "/:username",
    layout: LayoutBasic,
    component: User,
    exact: true,
  },

  {
    layout: LayoutBasic,
    component: Error404,
  },

 


];

export default routes;