
import React from 'react'
import { Image, Grid, GridColumn, GridRow  } from 'semantic-ui-react';
import {map } from "lodash"
import {Link} from "react-router-dom"
import UserNot from "./UserNot/UserNot"
import logo from "./../../asets/png/logo.jpg"
import zangano from "../../asets/png/zangano.png"
import elzangano from "../../asets/png/elzangano.jpeg"
import reina from "../../asets/png/reina.jpg" 
import aveja from "../../asets/png/aveja.jpg"
import gans from "../../asets/png/gans.png"
import mercado from "../../asets/png/mercado.png"
import niña from "../../asets/png/niña.png"
import cielo from "../../asets/png/cielo.png"
import flor from "../../asets/png/flor.png"
import ciudad from "../../asets/png/ciudad.png"


import "./Bienvenida.scss"


function Bienvenida() {
  return (
       <>
<Grid  class="six wide tablet eight wide computer column" mobile = {16} computer={5}  >

 <GridColumn className='homer'  mobile = {16} computer={5}>

        <Grid columns={1} centered>
        

 


<GridColumn>

  <h2>LA COLMENA </h2>
<Image src = {gans} circular />

<br />

</GridColumn>

</Grid>
 </GridColumn>





       
       
       
      <GridColumn className='homer_righ'  class="six wide tablet eight wide computer column"    mobile= {16}  computer={7} >
       
      <h2>Hola 😃 Bienvenid@</h2>
      <Grid columns={4} centered>
      <Grid.Row verticalAlign= "middle">
      <GridColumn>
      <Image src = {ciudad}  className='homer_righ_f' />
      </GridColumn>
      <GridColumn>
      <Image src = {cielo} circular className="homer_righ_f"  />
      <br />
      <Image src = {flor} circular className="homer_righ_f"  />
      </GridColumn>
      <GridColumn>
      <Image src = {niña}  className="homer_righ_f"  />
      </GridColumn>
      
      </Grid.Row>
      </Grid>


      <h3> Para iniciar CLICK en la home, visualiza las publicaciones de personas de tu comunidad, contacta  por medio de de whatsapp.
      </h3>
      </GridColumn>
    </Grid >

      <div>
    
      <Grid.Row className='homer_down'  mobile = {6} >
      <UserNot/>
      </Grid.Row>
      </div>
    </>
        
      
    
    
      
         
  )
}

export default Bienvenida