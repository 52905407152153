import React from 'react';
import { useQuery } from '@apollo/client';
import { map } from 'lodash';
import {GET_PUBLICS} from "../../../gql/publication";
import { Container, GridRow  } from 'semantic-ui-react';
import Publications from '../Publications';
import ModalPubication from '../../ModalPublicasion /ModalPubication';
import Preview from '../PreviewPublication./Preview';

import "./Public.scss";
import { GridColumn } from 'semantic-ui-react';




function Public(props) {
 const {publication } = props;


const {data, loading}= useQuery(GET_PUBLICS,{
  variables: {
    idPublication: publication.id  || publication._id,
  }

});

if (loading) return null;

const {getPublics} = data;

console.log (data);



  return (
    <>
   
    <GridRow className='description'> 
    
   <h2>Descripción</h2>
   
    
    

      </GridRow>

    <Container className='tex'>
    
        {map(getPublics, ( tublic, index)=> (
                    < div   key= { index } >

              
               
          
                <div className='titu_a'>
                
                  </div>
                  
                  <p> {tublic.description}</p>
                  
                </div>
               
              



          ))}
           
    
  
           </Container>   
    
    </>

   
  )
}



 

export default Public