import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import {useHistory} from "react-router-dom"
import {  useApolloClient } from '@apollo/client';
import GenerarReport from "../UtilForm/GenerarReport"
import { Link , } from 'react-router-dom';

import useAuth from '../../../../hooks/useAuth';

import "./UtilForm.scss";


export default function SettingsForm(props) {
    const {setShowModal , setTitleModal, setChildrenModal ,getUser ,refetch } = props;
    const history = useHistory ();
    const client = useApolloClient ();
    const {logout} = useAuth ();
    const {l} = Link ;



    const onChangeSearchUser= (l) =>  {
      setTitleModal("Buscar persona");
      
  
  
  
      setChildrenModal(history.push("/Buscar")  )
    };

    const onChangeEmail = () => {
      setTitleModal ("Generar ReporteS ");
     

    setChildrenModal (history.push("/SearchPublic" ))

     
        
       
     

    }

    const onChangeDescription = () => {
      setTitleModal( "Generar reporte general de usuarios "
       );
      setChildrenModal (<GenerarReport setShowModal={setShowModal}  /> );
      
    }
     
    const OnchangeRegistrar = () => {
      setTitleModal ("ingresa un nuevo usuario");
      setChildrenModal (history.push("/Registre" ) )
    }





    const onLogout = () => {
      client.clearStore();
      logout();
      history.push ("/");
    }


  return (
    <div className='setting-form'>
        <Button onClick={ onChangeSearchUser}> Buscar Usuario </Button>
        <Button onClick={onChangeEmail}> Buscar Peticion </Button>
        <Button onClick={onChangeDescription}> Reporte general de usuarios </Button>
        <Button onClick={OnchangeRegistrar}>Registrar Usuario </Button>
        <Button onClick={onLogout}> Cerrar sesion  </Button>
        <Button onClick={()=> setShowModal (false)}> Cancelar  </Button>
    </div>
  )
}
