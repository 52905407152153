import React from 'react';
import { Button } from 'semantic-ui-react';
import {useHistory} from "react-router-dom"
import {  useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import Comfirm from "../DeleteUser/ConfirmDelete";
import { useMutation } from '@apollo/client';


import useAuth from '../../../../../../hooks/useAuth';

import "./DeleteUser.scss";


export default function DeleteUser (props) {
    const {setShowModal , setTitleModal, setChildrenModal ,getUser ,refetch } = props;
    const history = useHistory ();
    const client = useApolloClient ();
    const {logout} = useAuth ();
    const {l} = Link ;



    const onChangePassword = (l) =>  {
      setTitleModal("Cambiar tu Contraseña");
      
      setChildrenModal(  logout = {onLogout} 
        
       )
    };

    const onChangeEmail = () => {
      setTitleModal ("generar reporte ");
     

    setChildrenModal (<Link to="/Rep"><Button>generar reporte total de usuarios </Button></Link>  );

     
        
       
     

    }

    const onChangeDescription = () => {
      setTitleModal( "Escribe el username del usuario " );
      setChildrenModal (<Comfirm
        setShowModal={setShowModal}
          currentUser = {getUser.username}
          refetch={refetch}
      
      />) ;
     
    }
     
    const onChangeWhattsApp = () => {
      setTitleModal ("confirmacion");
      setChildrenModal("hola") ;
      
    }





    const onLogout = () => {
      client.clearStore();
      logout();
      history.push ("/");
    }


  return (
    <div className='setting-form'>
       
        <Button onClick={onChangeEmail}> jjkj </Button>
        <Button onClick={onChangeDescription}> Borrar usuario </Button>
        <Button onClick={onChangeWhattsApp}>borrar usuario</Button>
        <Button onClick={onLogout}> Cerrar sesion  </Button>
        <Button onClick={()=> setShowModal (false)}> Cancelar  </Button>
    </div>
  )
}
