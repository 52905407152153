import React from 'react'
import { Icon, GridRow , Grid, Container ,Menu, MenuItem } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import "./Footer.scss"

const aviso = () => {
    
    alert("no has iniciado sesion ")

};


export default function footer() {
  return (



<>
<Menu icon mobile = {16} className='foot'  >
  
<MenuItem  >
<Icon name= "user secret"  onClick={aviso} />
</MenuItem>
  
<MenuItem>
<a href="whatsapp://send?text=Hola mira esta paguina local %20 de anuncios %20 https://lacolme.netlify.app/">
<Icon name= "share square"></Icon>
</a>
</MenuItem>


<MenuItem>
<Link to = "/info">
<Icon name= "question circle outline"/>
</Link>
</MenuItem>


<MenuItem>
<Link to = "/tm">
<Icon name= "trademark"/>
</Link>
</MenuItem>
</Menu>

</>

  )
}
