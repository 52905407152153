import React ,  {useEffect, useState}from 'react'
import { Button, Form ,TextArea } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {ADD_PUBLIC  } from '../../../gql/publication';
import Preview from '../../Publications/PreviewPublication./Preview';
import "./Title.scss";
import usePublic from '../../../hooks/usePublic';



export default function Title (props) {
const  { publication , setShowModal } = props;
const [addPublic] = useMutation (ADD_PUBLIC);

const  id = usePublic();

console.log(id)

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const formik = useFormik ({
  
  initialValues:{
    title: "",
    whats:"",
    description:"",
    precio:"",
    whats:"",
    
  },

  validationSchema:Yup.object({
    title:Yup.string().required(),
    whats:Yup.string().required()
    .matches(phoneRegExp, 'Whatsapp no valido')
    .min(10, "whats muy corto")
    .max(10, "whats muy largo"),
    description:Yup.string().required(),
    precio:Yup.string().required(),



    /*Yup.object()shape({
      decimal: Yup.number().test(
        'is-decimal',
        'invalid decimal',
        value => (value + "").match(/^\d*\.{1}\d*$/),
      ),
    })*/

  }),


  onSubmit: async (formData) =>{
    try {
      await addPublic ({
        variables:{
          input:{
            idPublication :id.id,
            title: formData.title,
            whats: formData.whats,
            description: formData.description,
            precio: formData.precio,

          },
        },
      });
      setShowModal (false);
      //refetch();
      
      console.log (formData);
    } catch (error) {

    
    }

    //window.location.reload()
  }

})








  return (
    
        <Form  className='titulo' onSubmit = {formik.handleSubmit}>
        <h2>
          Nombre de la publicasion  
        </h2>
        <Form.Input type= "text" placeholder = "Ingresa el Titulo de la publicasion"
         name= "title" value= {formik.values.title} 
         onChange = {formik.handleChange}
         //error={formik.error.title && true}

    />

        <h2> Whatsapp </h2>
         <Form.Input type= "Nunber" placeholder = "ingresa un Whats de contacto "
         name= "whats"  
         value = {formik.values.whats}
        onChange = {formik.handleChange}
        //error={formik.error.description && true}

      />


         <h2> Descripcion </h2>
         <TextArea  type= "text" placeholder = "ingresa la descripcion de la publicasion "
         name= "description"  
         value = {formik.values.description}
        onChange = {formik.handleChange}
        //error={formik.error.description && true}

      />

          <h2> Precio </h2>
          <Form.Input type= "text" placeholder = "indica el precio"
           name= "precio" value= {formik.values.precio} 
           onChange = {formik.handleChange}
           //error={formik.error.precio && true}

    />
      <Button type= "submit" className=' btn-submit'>  * Publicar * </Button> 

        </Form>
    
  )
}


