import React ,{  useState } from "react";
import {Icon,Image} from "semantic-ui-react"
import {Link} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../gql/user";
import useAuth from "../../hooks/useAuth";
import ModalUpload from "../ModalUpload";
import icona from "../../asets/png/icona.jpg";
import {useHistory} from "react-router-dom"
import {  useApolloClient } from '@apollo/client';
import "./RighHeader.scss";
import { get } from "lodash";



export default function RighHeader() {
  
  const[showModal , setShowModal ] = useState (false);
  const history = useHistory ();
  const client = useApolloClient ();
  const {logout} = useAuth ();


  const {auth} = useAuth();
  const {data,loading,error} = useQuery (GET_USER,{
    variables:{ username:auth.username},
  });

  if (loading ||error ) return null;
  const {getUser} = data ;
  
  const onLogout = () => {
    client.clearStore();
     logout();
    history.push ("/");
  }
  
  return (

    <>
    <div className ="righ-header"  >

    <Link to= {`/${auth.username}`}>
        <Image src= {getUser.avatar ? getUser.avatar : icona} avatar />
      </Link>
     
     
  
       <Icon name="plus" onClick ={() => setShowModal (true)} />
      
       <Icon name = "sign-out alternate" onClick={onLogout}/>


   </div>
   <ModalUpload show = {showModal} setShow={setShowModal}/>
                      
   

</>
  )
}
