import React from 'react'
import { Form, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from "yup" ;
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../../gql/user';
import "./WhatsAppF.scss";


export default function WhatsAppF(props) {
    const  {setShowModal,currentwhatsapp, refetch} = props
    const[updateUser] = useMutation(UPDATE_USER);


    const formik = useFormik ({
        initialValues: {
            whatsapp: currentwhatsapp || ""
        },
        validationSchema: Yup.object ({
            whatsapp: Yup.string().required(),
          }),
          onSubmit: async(FormData) => {
            try { await updateUser({
              variables: {
                input: FormData ,
                }
              });
              refetch();
              setShowModal(false);
            } catch (error) { 
              toast.error("error al actualizar ")
              
            }
    
          }   
    


    })
  
  
    return (
    
        <Form className='whatsappf'onSubmit={formik.handleSubmit}>
            <Form.Input placeholder="Escribe tu WhatsApp"
            name= "whatsapp" 
            value={formik.values.whatsapp}
            onChange={formik.handleChange}
            className={formik.errors.whatsapp && "error"}
            />


          <Button type= "submit" className='btn-submit'> actualizar </Button> 
        </Form>


        
  )
}
