
import React, {useState , useEffect} from 'react'
import { Search as SearchSU, Image ,GridRow, GridColumn, Grid, Icon } from "semantic-ui-react";
import {result, size} from "lodash"
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import icona from "./../../../src/asets/png/icona.jpg";
import { SEARCH } from '../../gql/user';




import "./SerachUser.scss";









export default function SerachUser() 
 
 {
   const [search , setSearch] = useState (null);
   const [results, setResults]= useState ([])
   const {data, loading} = useQuery (SEARCH, {
      variables:{search}

   });
   
   console.log (result);

   useEffect(() => {
     if (size(data?.search) > 0){
       const users = [];
       data.search.forEach((user, index)=> {
        users.push ({
            key:index,
            title:user.name,
            username:user.username,
            avatar: user.avatar,
            area:user.area,
        });
        });

        setResults (users);
        console.log (data );
        console.log (results);

     }else{
        setResults([]);
     }
   },[data])

   


const onChange = (e) => {
   if (e.target.value) setSearch (e.target.value);
   else setSearch(null);
}





  return (

    <>
    <Grid className='buscar' width={10}centered>
      <GridRow></GridRow>
    <GridRow>
      <Icon name="user outline" > </Icon>
      
    </GridRow>
   
   <GridRow>
        
        <h1>   Buscar Persona </h1>
      
        </GridRow>
    

    <SearchSU  className="searchU"
               fluid
               input={{ icon: "search", iconPosition: "left" }}
               loading = {loading}
               value = {search || ""}
               onSearchChange = {onChange}
               results = {results}
               resultRenderer={(e) => < ResultSearch data ={e} />}
    
    />

</Grid>

    </>
    


  );
}

function ResultSearch (props) {
    const {data} = props;
    console.log (data);

    return(
        <Link className='search-users_item' to= {`/${data.username}`}>
        <Image src ={data.avatar || icona} />
        <div>
        <p> {data.title}  </p>
        <p> {data.username}  </p>
        <p> {data.area}  </p>
        </div>
        </Link>
    )

}
