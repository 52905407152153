import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./UserNotFound.scss";



export default function UserNotFound() {
  return (


    <div className="user_not_found">
        
        <p> No encontrado </p>

        <p>
            Es posible que el enlace  que has segido sea incorrecto. 
        </p>

        <div>

        <Link className="icon" to = "/">
        <Icon name = "hand paper outline" />
        </Link>
        </div>
    </div> 
  )
}
