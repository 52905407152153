import React from 'react';
import {Container,Grid, Image , Icon} from "semantic-ui-react";
import {Link} from "react-router-dom"
import home from "../../../asets/png/home.png";
import RighHeader from '../RighHeadEx/RighHeadEx';
//import Search from './Search/Search';
import avat from "../../../asets/png/avat.png"
import logo5 from "../../../asets/png/logo5.png"

import "./HeaderEx.scss"; 

export default function Header() {
  return (

   <div className='headEx' mobile={16}>
    <Container>
    <Grid>
    <Grid.Column computer={3} mobile = {3}className="header__logo">
      <Link to = "/">
        <Image src = {logo5} alt= "clone" />
        <p>explorar</p>
        
        
        </Link>
    </Grid.Column>
    <Grid.Column computer={10} mobile= {8}>
      
      <h2>La Colme</h2>

    
        
    </Grid.Column>
    <Grid.Column computer={3} mobile={5}>

        <RighHeader/>

    </Grid.Column>




 </Grid>
 </Container>
   </div>
  )
}
