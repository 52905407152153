import React, {useState , useEffect, useRef} from 'react'

import { Image, Grid, GridColumn, GridRow,Search as SearchSU ,Icon } from 'semantic-ui-react';
import {result, size, map, set} from "lodash"
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import icona from "./../../asets/png/icona.jpg";
import orde from "./../../asets/png/orde.png";
import { GET_SEARCH_PUBLICATION } from '../../../src/gql/publication';


import "./SearchPublic.scss";
import Public from '../../components/Publications/public/Public';
import UserNotFollow from '../../components/Home/UserNotFollow';
import { asyncMap } from '@apollo/client/utilities'; 








export default function SearchPublic() {
  const [search , setSearch] = useState (null);
   const [results, setResults]= useState ([]);
   const [datos, setDatos]= useState([]);
   const inputRef = useRef ({});
   const {data, loading} = useQuery (GET_SEARCH_PUBLICATION, {
      variables:{search}

   });
   
   console.log (results);
   console.log (search);
   console.log(data);


  const onChange = (e) => { if (e.target.value) setSearch (e.target.value);
  else setSearch(null);
}




useEffect(() => {
  if (size(data?.searchPu ) > 0){
   
    const publics = [];

   /*
    data.searchPu.map((publi, index)=> {
      publics.push ({
          key:index,
          title:publi.title,
          idUser:publi.idUser,
         
                     
      });

      console.log("oyaaaaaaaaa", publi.idUser);

     
     
      


     /*

      publi.idUser.map((user, index)=> {
        publics.push ({
            key:index,

            
           
                       
        });
  
        
        });
  






      
      });




*/





    
      console.log("adadaaddadada")

    console.log("logggggggggggg");

    console.log("g");
      
    
    data.searchPu.forEach((publi) => {
      publics.push({
          

          idUser:publi.idUser,
          title:publi.title,
          descrupcion:publi.descripcion,
          
         

       } );
       console.log("ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",publi.idUser);

      





       






/*




        for (const key in publi.idUser) {
          if (Object.hasOwnProperty.call(publi.idUser, publi.title)) {
            const element = publi.idUser[publi.title];
            publics.push ({
            name: element.name
  
              
             
                         
          });
            
          }

          
        }

      

*/

    });

    

      
 
      

          






 
     
  
  
 
 


     
        
           
         
 
      
 





    















     setResults (publics);
     
 console.log(publics)
 console.log(data)
 

  }else{
     setResults([]);
     console.log("error")
  }
},[data]);



   
 

    
    
 
 
 
 
 
 
   return (


<>
    <Grid className='buscar' width={10}centered>
      <GridRow></GridRow>
    <GridRow>
      <Image src = {orde} />
      
    </GridRow>
   
   <GridRow>
        
        <h1>   Buscar Peticion  </h1>
      
        </GridRow>
    







     
     <SearchSU  className="searchU"
                fluid
                input={{ icon: "search", iconPosition: "left" }}
                loading = {loading}
                
                onSearchChange = {onChange}
                value = {search || ""}
                results = {results}
                resultRenderer={(e) => < ResultSearch data ={e}   />}
     />





</Grid>

    </>
    





   );

}

 
 
  function ResultSearch (props) {
     const {data , dato} = props;

     console.log ( "datos",data );

     

     //console.log(inputRef)


     
     
    
      
     


    // let  arra = Object.keys(data.idUser).map(key => {
      //  console.log(key)
//
    //    console.log("asasa")


        
     // }
      //)
    /* const bb = []
      data.idUser.map((user)=> {
        
        bb.push ({
          
          title:user.name,
          username:user.username,
          avatar: user.avatar,
          area:user.area,
      });
      })
*/    











      return(
        <>

        
      
       
      <div>

     <p>titulo: {data.title}  </p>

    <p>descripcion: {data.title}  </p>



{map(data.idUser, (se, title) => (
  
<p> usuario: {se}   </p>

))}

</div>




</>















    )

     
         
      

     
 
 }


 
 
