import React from 'react';
import {Form, Button, FormField, Checkbox, FormCheckbox,} from "semantic-ui-react";
import { useFormik , } from "formik";
import * as Yup from "yup" ;
import { toast } from "react-toastify";
import { useMutation } from '@apollo/client/react';
import {REGISTER} from "../../../gql/user";

import "../RegisterForm/RegisterForm.scss";





export default function RegisterForm(props) {
 const {setShowLogin} = props;
 const [register] = useMutation (REGISTER);
 

 const formik = useFormik ({
  initialValues:initialValue(),


  validationSchema: Yup.object({
    name: Yup.string().required("Tu nonbre es obligatorio"),
    username:Yup.string().matches( /^[a-zA-Z0-9-]*$/ , " No debe contener espacios " ).required ("El nombre del negocio o username es obligatorio"),
    email :Yup.string().email ("El email no es valido "),
    area :Yup.string().required(true),
    password: Yup.string ().required("La contraseña es requerida ").oneOf([Yup.ref("password2")],"Lascontraseñas no coinciden "),
    password2:Yup.string ().required("La contraseña es requerida ").oneOf([Yup.ref("password")],"Lascontraseñas no coinciden "),
    




  }),

  onSubmit: async (formv) => {try
    { const newUser = formv;
      console.log(formv);

       await register({
        variables:{
          "input": {
            name:newUser.name,
            username:newUser.username,
            area:newUser.area,
            email:newUser.email,
            password:newUser.password,
            adminis:newUser.adminis,
            
          



          }, 
        }
      });
      toast.success (" Bienvenido ");
      setShowLogin(true);
  }   catch (error)
  {
      toast.error(error.message);
      console.log (error)
    }
  },

 });


  return (
    <>
        <h2 className='register-form-title' mobile={16}>
            Registrate para comenzar. 
        </h2>
        <Form className='register-form' onSubmit = {formik.handleSubmit}>
            <Form.Input type="text"  placeholder = "Nombre y Apeidos" name="name"  value={formik.values.name} onChange ={formik.handleChange}
            error={formik.errors.name} />
            <Form.Input type="text"placeholder="Negocio o Username" name="username"  value={formik.values.username} onChange ={formik.handleChange}
             error= { formik.errors.username} />
             <Form.Dropdown placeholder= "COLONIA" options={CategoriesOptions} selection value= {formik.values.area} errors={formik.errors.area}
             onChange ={(_,data)=>formik.setFieldValue("area", data.value)}
             
             />
            <Form.Input type="text"placeholder="Correo Electronico" name="email" value={formik.values.email} onChange= {formik.handleChange} 
            error = {formik.errors.email && true} /> 
            <Form.Input type="text"placeholder="Contraseña" name="password" value={formik.values.password} onChange ={formik.handleChange} 
            error = {formik.errors.password}/>
            <Form.Input type="text"placeholder="Repite la Contraseña" name="password2" value={formik.values.password2} onChange ={formik.handleChange} 
            error = {formik.errors.password2}/>
            
          
            <Button type= "submit" className='btn-submit' > Registrarce </Button>
            </Form>
    </>
  );
}

function initialValue() {
  return  {
    "name":'' ,
    "username": "",
    "email": "",
    "password": "",
    "password2": "",
    "area": "",
    "adminis":false,
  };
};

const CategoriesOptions = [
  {key:"react",value:"La Colmena ", text:"La Colmena"},
  {key:"next", value:"Azcapotzaltongo", text:"Azcapotzaltongo"},
  {key:"nec", value:"Atizapan", text:"Atizapan"},
  {key:"nexti", value:"Coacalco", text:"Coacalco"},
  {key:"nexti", value:"Huehuetoca", text:"Huehuetoca"},
  {key:"nexti", value:"Isidro Fabela", text:"Isidro Fabela"},
  {key:"nexti", value:"Izcalli", text:"Izcalli"},
  {key:"nexti", value:"Naucalpan", text:"Naucalpan"},
  {key:"nexti", value:"Nicolas Romero", text:"Nicolas Romero"},
  {key:"nexti", value:"Tlanepantla", text:"Tlalnepantla"},
  {key:"nexti", value:"Toluca", text:"Toluca"},
  {key:"nexti", value:"Tultitlan", text:"Tultitlan"},
  {key:"nexti", value:"Villa del Carbon", text:"Villa del Carbon"},
  {key:"nexti", value:"Zumpango", text:"Zumpango"},
  {key:"nexti", value:"CDMX", text:"CDMX"},





];



console.log (initialValue);