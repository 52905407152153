import React,{useState} from "react";
import {Container, GridColumn, GridRow, Image , Grid} from "semantic-ui-react";
import RegisterForm from "../../components/Auth/RegisterForm";
import { Link , } from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import { Slide } from "react-slideshow-image";
import Auth from "./exterior/Auth";
import logo from "../../asets/png/logo.jpg";
import zangano from "../../asets/png/zangano.png"
import favela from "../../asets/png/favela.jpg"
import atizapan from "../../asets/png/atizapan.jpg"
import lguadalupe from "../../asets/png/lguadalupe.jpg"
import bramader from "../../asets/png/bramader.jpg"
import pedro from "../../asets/png/pedro.jpg"
import cielo4 from "../../asets/png/cielo4.png"
import cielo2 from "../../asets/png/cielo 2.png"
import avat from "../../asets/png/avat.png"
import Slider from "./slider/Slider";
import "./Portada.scss";
import { from } from "@apollo/client";



export default function Portada  (){
    const [showLogin, setShowLogin] = useState (true);



   // <Link to = "/HomeVenta">      <span>hola </span>              </Link>



   /*<a href = "/Registre"className = "whatsapp" 
   target= " _ blank"
     rel="noopener noreferrer"
      >
     <span >hola</span>
   </a>*/

    return (


    <>
  <Grid className="login" mobile={16} centered>
<GridRow className="miniatura" mobile={16}   computer={16}  >
<Image  src={cielo2}   tiny  />

<Image  src={bramader}   tiny />
<Image  src={pedro}   tiny  />
<Image  src={atizapan}   tiny  />
<Image  src={favela}   tiny />
<Image  src={cielo4}   tiny />

</GridRow>
<GridColumn fluid className="auth" mobile={16}>
<Image  src={avat}   circular />
<div className = "container-form">
{ showLogin ? (<LoginForm/>):(
<RegisterForm setShowLogin={ setShowLogin}/>
            )}

        </div>

      <div className="change-form">
        <p>{showLogin?(
            <>¿no tienes cuenta ? <span onClick={() => setShowLogin(!showLogin)}> Registrate</span >
            </>
        ) : (
            <> Entra con tu cuenta <span onClick={() => setShowLogin(!showLogin)}> Iniciar sesion </span>
            </>
        )}
            </p>
            </div>


  

       </GridColumn>


       <Container fluid  className ="conta"  mobile={16} >


        <h2> La Colme </h2>

    <span >Entra para comprar y vender articulos  usados y nuevos ,  de personas de tu municipio o colonia. 
      </span >

      

       </Container>
    

       </Grid>

       </>



    );
}