import React ,{useState} from 'react';
import {Form, Button, Container, FormField, Checkbox, FormCheckbox,} from "semantic-ui-react";
import { useFormik , } from "formik";
import * as Yup from "yup" ;
import { toast } from "react-toastify";
import { useMutation } from '@apollo/client/react';
import {REGISTER} from "../../gql/user";

import "./Registre.scss";





export default function Registre(props) {
 const {setShowLogin} = props;
 const [setTime , time] = useState (null);
 const [register] = useMutation (REGISTER);
 

 const formik = useFormik ({
  initialValues:initialValue(),


  validationSchema: Yup.object({
    name: Yup.string().required("Tu nonbre es obligatorio"),
    username:Yup.string().matches( /^[a-zA-Z0-9-]*$/ , " No debe contener espacios " ).required ("el nonbre de usuario es obligatorio"),
    email :Yup.string().email ("El email no es valido "),
    area :Yup.string().required(true),
    password: Yup.string ().required("La contraseña es requerida ").oneOf([Yup.ref("password2")],"Lascontraseñas no coinciden "),
    password2:Yup.string ().required("La contraseña es requerida ").oneOf([Yup.ref("password")],"Lascontraseñas no coinciden "),
    




  }),

  onSubmit: async (formv ,{resetForm}) => {try
    { const newUser = formv;
      console.log(formv);

       await register({
        variables:{
          "input": {
            name:newUser.name,
            username:newUser.username,
            area:newUser.area,
            email:newUser.email,
            password:newUser.password,
            adminis:newUser.adminis,
            
          



          }, 
        },
      });




      toast.success (" usuario agregado correctamente ");

      resetForm()
       
     
      //window.location.reload();
      
  }   catch (error)
  {
      toast.error(error.message);
      console.log (error)
    }
  },

 });


  return (

    <>

      <Container className='register-form-title'>
        <h2 >
            Registra Un Nuevo Usuario. 
        </h2>

        </Container>        

        <Container  fluid className = "register">
          <div className='container-form'>
        <Form className='regist' onSubmit = {formik.handleSubmit}>
            <Form.Input type="text"  placeholder = "Nombre y Apeidos" name="name"  value={formik.values.name} onChange ={formik.handleChange}
            error={formik.errors.name} />
            <Form.Input type="text"placeholder="Usuario" name="username"  value={formik.values.username} onChange ={formik.handleChange}
             error= { formik.errors.username} />
             <Form.Dropdown placeholder= "Deṕartamento" options={CategoriesOptions} selection value= {formik.values.area} errors={formik.errors.area}
             onChange ={(_,data)=>formik.setFieldValue("area", data.value)}
             
             />
            <Form.Input type="text"placeholder="Correo Electronico" name="email" value={formik.values.email} onChange= {formik.handleChange} 
            error = {formik.errors.email && true} /> 
            <Form.Input type="text"placeholder="Contraseña" name="password" value={formik.values.password} onChange ={formik.handleChange} 
            error = {formik.errors.password}/>
            <Form.Input type="text"placeholder="Repite la Contraseña" name="password2" value={formik.values.password2} onChange ={formik.handleChange} 
            error = {formik.errors.password2}/>
            <FormCheckbox label ="administrador"  checked={formik.values.adminis} onChange ={(_,data)=>formik.setFieldValue("adminis",data.checked)} 
            error= { formik.errors.adminis}
            />
          
            <Button type= "submit" className='btn-submit'> Registrar </Button>
            </Form>
            </div>

            </Container>

    </>
  );
}

function initialValue() {
  return  {
    "name":'' ,
    "username": "",
    "email": "",
    "password": "",
    "password2": "",
    "area": "",
    //"adminis":true,
  };
};

const CategoriesOptions = [
  {key:"react",value:"LA COLMENA", text:"LA COLMENA"},
  {key:"next", value:"SAN P AZCAPOZALTONGO", text:"AZCAPOZALTONGO"},
  {key:"nec", value:"soporte", text:"Soporte Tecnico"},
];


console.log (initialValue);