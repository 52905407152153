import React from 'react';
import {Container,Grid, Image , Icon} from "semantic-ui-react";
import {Link} from "react-router-dom"
import home from "../../asets/png/home.png";
import RighHeader from './../RighHeader';
import Search from './Search/Search';
import "./Header.scss"; 

export default function Header() {
  return (

   <div className='header' mobile={16}>
    <Container>
    <Grid>
    <Grid.Column computer={3} mobile = {3}className="header__logo">
      <Link to = "/HomeVenta">
        <Image src = {home} alt= "clone" />
        
        </Link>
    </Grid.Column>
    <Grid.Column computer={10} mobile= {4}>
        <Search/>
    </Grid.Column>
    <Grid.Column computer={3} mobile={4}>

        <RighHeader/>

    </Grid.Column>




 </Grid>
 </Container>
   </div>
  )
}
