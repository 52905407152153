import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import {getToken} from "../utils/token";

const httpLink = createUploadLink({
    uri:  
    //"http://192.168.1.29:4000/"

   "https://apipi.lacolme.com/"
      
    
    //"https://lacolmes-production.up.railway.app/"

});

const authLink =  setContext ((_, {headers}) => {
 const token = getToken ();
 console.log(setContext);

 return{


   headers:{
        ...headers,
        Authorization: token ? ` ${token}` : "",
    },
 };
});


const client = new ApolloClient({
    connectToDevTools: true ,
    cache: new InMemoryCache (),
    link: authLink.concat(httpLink),
});

export default client ;
